import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Col, Form, Input, Row, Button, message, Select, Card, Upload } from "antd";
import {IconButton} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { UploadOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { FormStyle } from "../../../comons/styles";
import { useAnalisisTrabajo } from "../../../../hooks/administrativa/formularios-sig/useAnalisisTrabajo";
import { initialValuesAnalisisTrabajo } from "../components/initialValues";
import selectData from "../components/json/analisis-trabajo.json"
import FilePreviewModal from "../../../comons/PreviewFiles/ModalPreview";
import { analisisTrabajoSchema } from "../components/validations";

export function AnalisisTrabajoForm(props) {

  const { updateAnalisisRiesgos, cancelLoader, loading } = useAnalisisTrabajo();
  const { dataTipoFormulario, data, handleNext, handlePrev, id, setnewdatanewdataAnalisisTrabajo } = props;
  const { Option } = Select;
  const [fileList, setFileList] = useState([]);
  
  const selectRiesgos = selectData.riesgos;
  const selectRiesgosBiologicos = selectData.riesgos_biologicos;
  const selectRiesgosFisicos = selectData.riesgos_fisicos;
  const selectRiesgosQuimicos = selectData.riesgos_quimicos;
  const selectRiesgosPsicosocial = selectData.riesgos_psicosocial;
  const selectRiesgosBiomecanico = selectData.riesgos_biomecanicos;
  const selectRiesgosCondicionesSeguridad = selectData.riesgos_condiciones_seguridad;
  const selectRiesgosFenomenosNaturales = selectData.riesgos_fenomenos_naturales;
  const selectMedidaspre = selectData.medidas_preventivas;
  const selectNa = selectData.no_aplica;

  useEffect(() => {
    cancelLoader()
  }, [data])

  const formik = useFormik({
    initialValues: initialValuesAnalisisTrabajo(id.Id_formulario, data, true),
    //validationSchema: analisisTrabajoSchema,
    onSubmit: async (formValues) => {
      try {
        await updateAnalisisRiesgos(id.Id_analisistrabajo, formValues);
        message.success("Actualización exitosa (Analisis de riesgos)");
        setnewdatanewdataAnalisisTrabajo(formValues)
        handleNext();
      } catch (error) {
        message.error("Error en la operación (Analisis de riesgos)");
      }
    },
  });

  const handleChange = (file) => {
    const isValidType = /\.(pdf|png|jpeg|jpg)$/i.test(file.name);
    if (!isValidType) {
    message.error('Solo se permiten archivos .pdf, .png, .jpeg, .jpg');
    return Upload.LIST_IGNORE; // Ignora el archivo adjuntado en el formulario
    }

    formik.setFieldValue('evidencia', file); // Añade el archivo en el campo de formik de "archivo"
    setFileList([file]); // Actualiza el estado con el archivo
    return false; // Previene la subida automática del archivo
  };

  const addRow = () => {
    formik.setValues({
      ...formik.values,
      peligros_schema: [
        ...formik.values.peligros_schema,
        {
          peligros: "",
          riesgos: "",
          controlesrequeridos: "",
          consecuencia: "",
          responsables: "",
          tarea: "",
        }
      ]
    });
  };

  const removeRow = (index) => {
    const updatedRows = [...formik.values.peligros_schema];
    updatedRows.splice(index, 1);
    formik.setValues({
      ...formik.values,
      peligros_schema: updatedRows
    });
  };

  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </div>
    ) : (

    <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
      {formik.values.peligros_schema.map((rowPeligros, index) => (
        <div key={index} style={{ border: "1px solid #ccc", padding: "16px", marginBottom: "16px" }}>
          {dataTipoFormulario !== "PT Alturas" && (
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                    label="Peligros (Clasificación)"
                    validateStatus={formik.errors?.peligros_schema?.[index]?.peligros && formik.touched?.peligros_schema?.[index]?.peligros ? "error" : ""}
                    help={formik.touched?.peligros_schema?.[index]?.peligros && formik.errors?.peligros_schema?.[index]?.peligros ? formik.errors?.peligros_schema?.[index]?.peligros : ""}
                >
                    <Select
                      name={`peligros_schema[${index}].peligros`}
                      value={rowPeligros.peligros}
                      onChange={(value) => formik.setFieldValue(`peligros_schema[${index}].peligros`, value)}
                      onBlur={formik.handleBlur}
                      placeholder="Selecciona una opción"
                    >
                      {selectRiesgos && !selectRiesgos.error && selectRiesgos.map(opcion => (
                        <Option key={opcion} value={opcion}>
                          {opcion}
                        </Option>
                      ))}
                    </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                    label="Peligros (Descripción)"
                    validateStatus={formik.errors?.peligros_schema?.[index]?.riesgos && formik.touched?.peligros_schema?.[index]?.riesgos ? "error" : ""}
                    help={formik.touched?.peligros_schema?.[index]?.riesgos && formik.errors?.peligros_schema?.[index]?.riesgos ? formik.errors?.peligros_schema?.[index]?.riesgos : ""}
                >
                    <Select
                      name={`peligros_schema[${index}].riesgos`}
                      value={rowPeligros.riesgos}
                      onChange={(value) => formik.setFieldValue(`peligros_schema[${index}].riesgos`, value)}
                      onBlur={formik.handleBlur}
                      placeholder="Selecciona una opción"
                    >
                    { rowPeligros.peligros == "Biológico" && (
                      <>
                        {selectRiesgosBiologicos && !selectRiesgosBiologicos.error && selectRiesgosBiologicos.map(opcion => (
                          <Option key={opcion} value={opcion}>
                            {opcion}
                          </Option>
                        ))}
                      </>
                    )}
                    { rowPeligros.peligros == "Biomecánico" && (
                      <>
                        {selectRiesgosBiomecanico && !selectRiesgosBiomecanico.error && selectRiesgosBiomecanico.map(opcion => (
                          <Option key={opcion} value={opcion}>
                            {opcion}
                          </Option>
                        ))}
                      </>
                    )}
                    { rowPeligros.peligros == "Condiciones de seguridad" && (
                      <>
                        {selectRiesgosCondicionesSeguridad && !selectRiesgosCondicionesSeguridad.error && selectRiesgosCondicionesSeguridad.map(opcion => (
                          <Option key={opcion} value={opcion}>
                            {opcion}
                          </Option>
                        ))}
                      </>
                    )}
                    { rowPeligros.peligros == "Fenómenos naturales" && (
                      <>
                        {selectRiesgosFenomenosNaturales && !selectRiesgosFenomenosNaturales.error && selectRiesgosFenomenosNaturales.map(opcion => (
                          <Option key={opcion} value={opcion}>
                            {opcion}
                          </Option>
                        ))}
                      </>
                    )}
                    { rowPeligros.peligros == "Físico" && (
                      <>
                        {selectRiesgosFisicos && !selectRiesgosFisicos.error && selectRiesgosFisicos.map(opcion => (
                          <Option key={opcion} value={opcion}>
                            {opcion}
                          </Option>
                        ))}
                      </>
                    )}
                    { rowPeligros.peligros == "Psicosocial" && (
                      <>
                        {selectRiesgosPsicosocial && !selectRiesgosPsicosocial.error && selectRiesgosPsicosocial.map(opcion => (
                          <Option key={opcion} value={opcion}>
                            {opcion}
                          </Option>
                        ))}
                      </>
                    )}
                    { rowPeligros.peligros == "Químico" && (
                      <>
                        {selectRiesgosQuimicos && !selectRiesgosQuimicos.error && selectRiesgosQuimicos.map(opcion => (
                          <Option key={opcion} value={opcion}>
                            {opcion}
                          </Option>
                        ))}
                      </>
                    )}
                    { rowPeligros.peligros == "No Aplica" && (
                      <>
                        {selectNa && !selectNa.error && selectNa.map(opcion => (
                          <Option key={opcion} value={opcion}>
                            {opcion}
                          </Option>
                        ))}
                      </>
                    )}
                    
                    </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                    label="Controles requeridos"
                    validateStatus={formik.errors?.peligros_schema?.[index]?.controlesrequeridos && formik.touched?.peligros_schema?.[index]?.controlesrequeridos ? "error" : ""}
                    help={formik.touched?.peligros_schema?.[index]?.controlesrequeridos && formik.errors?.peligros_schema?.[index]?.controlesrequeridos ? formik.errors?.peligros_schema?.[index]?.controlesrequeridos : ""}
                >
                    <Select
                      name={`peligros_schema[${index}].controlesrequeridos`}
                      value={rowPeligros.controlesrequeridos}
                      onChange={(value) => formik.setFieldValue(`peligros_schema[${index}].controlesrequeridos`, value)}
                      onBlur={formik.handleBlur}
                      placeholder="Selecciona una opción"
                    >
                      {selectMedidaspre && !selectMedidaspre.error && selectMedidaspre.map(opcion => (
                        <Option key={opcion} value={opcion}>
                          {opcion}
                        </Option>
                      ))}
                    </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
          <Col span={8}>
              <Form.Item
                  label="Consecuencias"
                  validateStatus={formik.errors?.peligros_schema?.[index]?.consecuencia && formik.touched?.peligros_schema?.[index]?.consecuencia ? "error" : ""}
                  help={formik.touched?.peligros_schema?.[index]?.consecuencia && formik.errors?.peligros_schema?.[index]?.consecuencia ? formik.errors?.peligros_schema?.[index]?.consecuencia : ""}
              >
                  <Input
                    name={`peligros_schema[${index}].consecuencia`}
                    value={rowPeligros.consecuencia}
                    onChange={(e) => formik.setFieldValue(`peligros_schema[${index}].consecuencia`, e.target.value)}
                    onBlur={formik.handleBlur} 
                  />
              </Form.Item>
            </Col>
          { dataTipoFormulario !== "PT Alturas" && (
            <Col span={8}>
              <Form.Item
                  label="Responsables"
                  validateStatus={formik.errors?.peligros_schema?.[index]?.responsables && formik.touched?.peligros_schema?.[index]?.responsables ? "error" : ""}
                  help={formik.touched?.peligros_schema?.[index]?.responsables && formik.errors?.peligros_schema?.[index]?.responsables ? formik.errors?.peligros_schema?.[index]?.responsables : ""}
              >
                  <Input
                    name={`peligros_schema[${index}].responsables`}
                    value={rowPeligros.responsables}
                    onChange={(e) => formik.setFieldValue(`peligros_schema[${index}].responsables`, e.target.value)}
                    onBlur={formik.handleBlur} 
                  />
              </Form.Item>
            </Col>
          )}

            <Col span={8}>
              <Form.Item
                  label="Tareas a realizar"
                  validateStatus={formik.errors?.peligros_schema?.[index]?.tarea && formik.touched?.peligros_schema?.[index]?.tarea ? "error" : ""}
                  help={formik.touched?.peligros_schema?.[index]?.tarea && formik.errors?.peligros_schema?.[index]?.tarea ? formik.errors?.peligros_schema?.[index]?.tarea : ""}
              >
                  <Input.TextArea
                    rows={1}
                    name={`peligros_schema[${index}].tarea`}
                    value={rowPeligros.tarea}
                    onChange={(e) => formik.setFieldValue(`peligros_schema[${index}].tarea`, e.target.value)}
                    onBlur={formik.handleBlur}
                    autoSize
                  />
              </Form.Item>
            </Col>
            <Col span={8}>
              {/* Botón para eliminar la fila */}
              <Button
                type="primary"
                icon={<MinusCircleOutlined />}
                onClick={() => removeRow(index)}
                style={{ marginTop: "30px" }}
                danger
              >
                Eliminar fila
              </Button>
            </Col>
          </Row>
        </div>
      ))}

      <Col span={8}>
        <Form.Item
          label=" "
          validateStatus={formik.errors.evidencia && formik.touched.evidencia ? "error" : "" }
          help={formik.touched.evidencia && formik.errors.evidencia ? formik.errors.evidencia : "" }
        >
            <Upload
              name="evidencia"
              accept=".pdf,.png,.jpeg,.jpg"
              fileList={fileList}
              beforeUpload={handleChange}
              maxCount={1}
              onRemove={() => {
                formik.setFieldValue("evidencia", null);
                setFileList([]);
              }}
            >
              <Button  icon={<UploadOutlined />}>Adjuntar Soportes</Button>
            </Upload>
        </Form.Item>
      </Col>

      <Button type="primary" icon={<PlusOutlined />} onClick={addRow}>
        Agregar fila
      </Button>

      <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
          <Button type="primary" htmlType="button" onClick={handlePrev}>
            Anterior
          </Button>
          <Button type="primary" htmlType="submit">
            Actualizar
          </Button>
          <Button type="primary" style={{ background: "black", marginLeft: "5px" }} onClick={handleNext}>
            Siguiente
          </Button>
      </div>
    </Form>
  )}
  </>
  )
}

export function AnalisisTrabajoFullView(props) {
  const { cancelLoader, loading } = useAnalisisTrabajo();
  const { dataTipoFormulario, data } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const handlePreviewOpen = (url) => {
      setPreviewUrl(url);
      setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
      setPreviewOpen(false);
      setPreviewUrl('');
  };

  useEffect(() => {
    cancelLoader();
  }, [data, cancelLoader]);

  const formik = useFormik({
    initialValues: initialValuesAnalisisTrabajo(data.Id_formulario, data, false),
  });

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Row style={{ fontSize: '25px', fontWeight: 'bold', color: 'rgb(204, 48, 43)' }}>
          Análisis de trabajo
          </Row>
          <hr />
          Evidencias

          {formik.values.evidencia !== null ? (
            <IconButton onClick={() => handlePreviewOpen(formik.values.evidencia)}>
              <VisibilityIcon />
            </IconButton>
          ) : (
            <p>No hay evidencias de análisis de trabajo</p>
          )}
          {formik.values.peligros_schema.length > 0 ? (
            <Form layout="vertical">
              {formik.values.peligros_schema.map((rowPeligros, index) => (
                <div
                  key={index}
                  style={{ border: '1px solid #ccc', padding: '16px', marginBottom: '16px' }}
                >
                  {dataTipoFormulario !== 'PT Alturas' && (
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item label="Peligros (Clasificación)">
                          <Input
                            name={`peligros_schema[${index}].peligros`}
                            value={rowPeligros.peligros}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Peligros (Descripción)">
                          <Input
                            name={`peligros_schema[${index}].riesgos`}
                            value={rowPeligros.riesgos}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Controles requeridos">
                          <Input
                            name={`peligros_schema[${index}].controlesrequeridos`}
                            value={rowPeligros.controlesrequeridos}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Consecuencia">
                          <Input
                            name={`peligros_schema[${index}].consecuencia`}
                            value={rowPeligros.consecuencia}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    {dataTipoFormulario !== 'PT Alturas' && (
                      <Col span={8}>
                        <Form.Item label="Responsables">
                          <Input
                            name={`peligros_schema[${index}].responsables`}
                            value={rowPeligros.responsables}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={8}>
                      <Form.Item label="Tareas a realizar">
                        <Input.TextArea
                          rows={1}
                          name={`peligros_schema[${index}].tarea`}
                          value={rowPeligros.tarea}
                          readOnly
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </Form>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '20vh',
              }}
            >
              <Card
                style={{
                  border: '1px #FF5656 solid',
                }}
              >
                <p>No hay datos de análisis de trabajo</p>
              </Card>
            </div>
          )}
        </>
      )}

      <FilePreviewModal
        open={previewOpen}
        handleClose={handlePreviewClose}
        url={previewUrl}
      />
    </>
  );
}