import React, { useEffect } from "react";
import * as Yup from "yup";
import moment from 'moment';
import { Button, Col, Form, Row, message, Input, DatePicker, Card, Table, Spin } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useFormik } from "formik";
import { useQuienDifunde } from "../../../../hooks/administrativa/formularios-sig/useQuienDifunde";
import { initialValuesQuienDifunde } from "../components/initialValues";
import { CircularProgress } from "@mui/material";
import { FormStyle } from "../../../comons/styles";
import dayjs from "dayjs";

export function QuienDifundeForm(props) {

  const { data, handleNext, handlePrev, id, setnewdataDifundidoA } = props;
  const { updateQuienDifunde, cancelLoader, loading } = useQuienDifunde();

  useEffect(() => {
    cancelLoader()
  }, [data])
  
  const formik = useFormik({
    initialValues: initialValuesQuienDifunde(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,

    onSubmit: async (formValues) => {
      try {
        await updateQuienDifunde(id.Id_difundidoa, formValues);
        message.success("Actualización exitosa (Quien difunde)");
        setnewdataDifundidoA(formValues);
        handleNext();
      } catch (error) {
        message.error("Error en la operación (Quien difunde)");
      }
    },
  });

  const addRow = () => {
    formik.setValues({
      ...formik.values,
      difundidos: [
        ...formik.values.difundidos,
        {
          nombredifundido: "",
          ceduladifundido: "",
          cargodifundido: "",
          emaildifundido: "",
          fecha_difundido: null,
          estado: "Pendiente",
          last_update: null,
        }
      ]
    });
  };

  
  
  const removeRow = (index) => {
    const updatedRows = [...formik.values.difundidos];
    updatedRows.splice(index, 1);
    formik.setValues({
      ...formik.values,
      difundidos: updatedRows
    });
  };
  
  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </div>
    ) : (
      <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
      {formik.values.difundidos.map((difundido, index) => (
        <div key={index} style={{ border: "1px solid #ccc", padding: "16px", marginBottom: "16px" }}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Nombre y apellidos"
                validateStatus={formik.errors?.difundidos?.[index]?.nombredifundido && formik.touched?.difundidos?.[index]?.nombredifundido ? "error" : ""}
                help={formik.touched?.difundidos?.[index]?.nombredifundido && formik.errors?.difundidos?.[index]?.nombredifundido ? formik.errors?.difundidos?.[index]?.nombredifundido : ""}
                required
              >
                <Input
                  name={`difundidos[${index}].nombredifundido`}
                  value={difundido.nombredifundido}
                  onChange={(e) => formik.setFieldValue(`difundidos[${index}].nombredifundido`, e.target.value)}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Cédula"
                validateStatus={formik.errors?.difundidos?.[index]?.ceduladifundido && formik.touched?.difundidos?.[index]?.ceduladifundido ? "error" : ""}
                help={formik.touched?.difundidos?.[index]?.ceduladifundido && formik.errors?.difundidos?.[index]?.ceduladifundido ? formik.errors?.difundidos?.[index]?.ceduladifundido : ""}
                required
              >
                <Input
                  name={`difundidos[${index}].ceduladifundido`}
                  value={difundido.ceduladifundido}
                  onChange={(e) => formik.setFieldValue(`difundidos[${index}].ceduladifundido`, e.target.value)}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Cargo"
                validateStatus={formik.errors?.difundidos?.[index]?.cargodifundido && formik.touched?.difundidos?.[index]?.cargodifundido ? "error" : ""}
                help={formik.touched?.difundidos?.[index]?.cargodifundido && formik.errors?.difundidos?.[index]?.cargodifundido ? formik.errors?.difundidos?.[index]?.cargodifundido : ""}
                required
              >
                <Input
                  name={`difundidos[${index}].cargodifundido`}
                  value={difundido.cargodifundido}
                  onChange={(e) => formik.setFieldValue(`difundidos[${index}].cargodifundido`, e.target.value)}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Email"
                validateStatus={formik.errors?.difundidos?.[index]?.emaildifundido && formik.touched?.difundidos?.[index]?.emaildifundido ? "error" : ""}
                help={formik.touched?.difundidos?.[index]?.emaildifundido && formik.errors?.difundidos?.[index]?.emaildifundido ? formik.errors?.difundidos?.[index]?.emaildifundido : ""}
                required
              >
                <Input
                  name={`difundidos[${index}].emaildifundido`}
                  value={difundido.emaildifundido}
                  onChange={(e) => formik.setFieldValue(`difundidos[${index}].emaildifundido`, e.target.value)}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Fecha Difundido"
                validateStatus={formik.errors?.difundidos?.[index]?.fecha_difundido && formik.touched?.difundidos?.[index]?.fecha_difundido ? "error" : ""}
                help={formik.touched?.difundidos?.[index]?.fecha_difundido && formik.errors?.difundidos?.[index]?.fecha_difundido ? formik.errors?.difundidos?.[index]?.fecha_difundido : ""}
                required
              >
                {/* <DatePicker
                  showTime
                  style={{ width: '100%' }}
                  value={difundido.fecha_difundido ? moment(difundido.fecha_difundido) : null}
                  onChange={(date, dateString) => formik.setFieldValue(`difundidos[${index}].fecha_difundido`, dateString)}
                  onBlur={() => formik.setFieldTouched(`difundidos[${index}].fecha_difundido`)}
                /> */}

                <DatePicker
                    style={{ width: '100%' }}
                    format="YYYY-MM-DD HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    value={difundido.fecha_difundido ? dayjs(difundido.fecha_difundido) : null}
                    onChange={(value) => {
                        const newDate = value ? value.toISOString() : null;
                        formik.setFieldValue(`difundidos[${index}].fecha_difundido`, newDate);
                    }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              {/* Botón para eliminar la fila */}
              <Button
                type="primary"
                icon={<MinusCircleOutlined />}
                onClick={() => removeRow(index)}
                style={{ marginTop: "32px" }}
                danger
              >
                Eliminar fila
              </Button>
            </Col>
          </Row>
        </div>
      ))}
      <Button type="primary" icon={<PlusOutlined />} onClick={addRow}>
        Agregar fila
      </Button>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
            <Button type="primary" htmlType="button" onClick={handlePrev}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Actualizar
            </Button>
            <Button type="primary" style={{ background: "black", marginLeft: "5px" }} onClick={handleNext}>
              Siguiente
            </Button>
        </div>
      </Form>
      )}
    </>

  );
}


export function QuienDifundeFullView(props) {
  const { data } = props;
  const { cancelLoader, loading } = useQuienDifunde();

  useEffect(() => {
    cancelLoader();
  }, [data]);

  const formik = useFormik({
    initialValues: initialValuesQuienDifunde(data.Id_formulario, data),
  });

  const columns = [
    {
      title: 'Nombre y Apellidos',
      dataIndex: 'nombredifundido',
      key: 'nombredifundido',
    },
    {
      title: 'Cédula',
      dataIndex: 'ceduladifundido',
      key: 'ceduladifundido',
    },
    {
      title: 'Cargo',
      dataIndex: 'cargodifundido',
      key: 'cargodifundido',
    },
    {
      title: 'Email',
      dataIndex: 'emaildifundido',
      key: 'emaildifundido',
    },
    {
      title: 'Fecha Difundido',
      dataIndex: 'fecha_difundido',
      key: 'fecha_difundido',
      render: text => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : null),
    },
    {
      title: 'Último Cambio Estado',
      dataIndex: 'last_update',
      key: 'last_update',
      render: text => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : null),
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      render: estado => (
        <Button
          type="primary"
          disabled
          style={{
            background:
              estado === 'Pendiente'
                ? '#B0B0B0'
                : estado === 'Aprobado'
                ? '#7EEB0A'
                : estado === 'Rechazado'
                ? '#CC302B'
                : undefined,
            color: 'black',
            width: '100%',
          }}
        >
          {estado}
        </Button>
      ),
    },
  ];

  const dataSource = formik.values.difundidos.map((difundido, index) => ({
    ...difundido,
    key: index,
  }));

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row style={{ fontSize: '25px' }}>Difundidos</Row>
      <hr />
      {formik.values.difundidos.length > 0 ? (
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20vh',
          }}
        >
          <Card
            style={{
              border: '1px #FF5656 solid',
            }}
          >
            <p>No hay datos de difundidos</p>
          </Card>
        </div>
      )}
    </>
  );
}

export default QuienDifundeFullView;

// export function QuienDifundeFullView(props) {
 
//   const { data } = props;
//   const { cancelLoader, loading } = useQuienDifunde();

//   useEffect(() => {
//     cancelLoader()
//   }, [data])
  
//   const formik = useFormik({
//     initialValues: initialValuesQuienDifunde(data.Id_formulario, data),
//   });

//   return (
//     <>
//     {loading ? (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     ) : (
//     <>
//     <Row style={{ fontSize: '25px' }} >Difundidos</Row>
//     <hr/>
//     {formik.values.difundidos.length > 0 ? (
//       <Form layout="vertical"  style={{ overflowY: 'scroll', height: '250px'}}>
//       {formik.values.difundidos.map((difundido, index) => (
//         <div key={index} style={{ border: "1px solid #ccc", padding: "16px", marginBottom: "16px" }}>
//           <Row gutter={16}>
//             <Col span={8}>
//               <Form.Item label="Nombre y apellidos" >
//                 <Input
//                   name={`difundidos[${index}].nombredifundido`}
//                   value={difundido.nombredifundido}
//                   readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label="Cédula" >
//                 <Input
//                   name={`difundidos[${index}].ceduladifundido`}
//                   value={difundido.ceduladifundido}
//                   readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item label="Cargo" >
//                 <Input
//                   name={`difundidos[${index}].cargodifundido`}
//                   value={difundido.cargodifundido}
//                   readOnly
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={8}>
//             <Col span={8}>
//               <Form.Item label="Email" >
//                 <Input
//                   name={`difundidos[${index}].emaildifundido`}
//                   value={difundido.emaildifundido}
//                   readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={5}>
//               <Form.Item label="Fecha Difundido" >
//                 <Input
//                   style={{ width: '100%' }}
//                   value={difundido.fecha_difundido ? moment(difundido.fecha_difundido).format('DD/MM/YYYY HH:mm:ss') : null}
//                   readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={5}>
//               <Form.Item label="Ultimo cambio estado" >
//                 <Input
//                   style={{ width: '100%' }}
//                   value={difundido.last_update ? moment(difundido.last_update).format('DD/MM/YYYY HH:mm:ss') : null}
//                   readOnly
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={6}>
//               <Form.Item style={{ marginTop: 30}}>
//               <Button
//                 type="primary"
//                 disabled
//                 style={{
//                   background:
//                     difundido.estado === 'Pendiente'
//                       ? '#B0B0B0'
//                       : difundido.estado === 'Aprobado'
//                       ? '#7EEB0A'
//                       : difundido.estado === 'Rechazado'
//                       ? '#CC302B'
//                       : undefined,
//                   color: "black",
//                   width: '100%',
//                 }}
//               >
//                 {difundido.estado}
//               </Button>
//               </Form.Item> 
//             </Col>
//           </Row>
//         </div>
//       ))}
//       </Form>
//           ) : (
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 height: "20vh",
//               }}
//             >
//               <Card
//                 style={{
//                   border: '1px #FF5656 solid',
                  
//                 }}
//               >
//               <p>No hay datos de difundidos</p>
//               </Card>
//             </div>
//           )}
//         </>
//       )}
//     </>
//   );
// }