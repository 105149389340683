export function initialValuesDatosActividad(data, Id_creador, creador_name) {
    return {
        /* Formulario datos de actividad */
        tipoformulario: data?.tipoformulario || "" ,
        fecha_elaboracion: data?.fecha_elaboracion || null,
        fecha_inicio: data?.fecha_inicio || null,
        fecha_final: data?.fecha_final || null,
        num_orden: data?.num_orden || "",
        empresa: data?.empresa || "",
        lugartrabajo: data?.lugartrabajo || "",
        direccion: data?.direccion || "",
        num_trabajadores: data?.num_trabajadores || "",
        num_llamada_servicios: data?.num_llamada_servicios ?? "",
        herramienta: data?.herramienta || "",
        trabajo: data?.trabajo || "",
        permisotrabajo: data?.permisotrabajo || "",
        num_permiso: data?.num_permiso || "",
        altura: data?.altura || "",
        Fechaterminacion: data?.Fechaterminacion || null,
        Id_supervisor: data?.Id_supervisor || null,
        Id_analisistrabajo: data?.Id_analisistrabajo || null,
        Id_creador: Id_creador || null,
        creador: creador_name || "",
        Id_ats: data?.Id_ats || null,
    }
}

export function initialValuesRiesgoPotencial(id, data) {
    return {
        /* Riesgo Potencial */
        Id_formulario: id || "",
        expsustanciasquimicas: data?.expsustanciasquimicas || "No",
        caidasuperior15: data?.caidasuperior15 || "No",
        riesgobiologico: data?.riesgobiologico || "No",
        maquinapesada: data?.maquinapesada || "No",
        espaciosconfinados: data?.espaciosconfinados || "No",
        superficiecaliente: data?.superficiecaliente || "No",
        riesgoelectrico: data?.riesgoelectrico || "No",
        ruido: data?.ruido || "No",
        manipulacioncarga: data?.manipulacioncarga || "No",
        afmosferapeligrosa: data?.afmosferapeligrosa || "No",
        equipomovimiento: data?.equipomovimiento || "No",
        caidaobjetos: data?.caidaobjetos || "No",
        herramientamanual: data?.herramientamanual || "No",
        izajecarga: data?.izajecarga || "No",
        derrames: data?.derrames || "No",
        precionesanormales: data?.precionesanormales || "No",
        radiaciones: data?.radiaciones || "No",
        esquirlas: data?.esquirlas || "No",
        incendio: data?.incendio || "No",
        otrosriesgos: data?.otrosriesgos || "N/A",
    }
}

export function initialValuesControlRiesgos(id, data) {
    return {
        /* Control de riesgos */
        Id_formulario: id || "",
        //EPP
        proteccioncabeza: data?.proteccioncabeza || "",
        proteccionfacial: data?.proteccionfacial || "",
        proteccionvisual: data?.proteccionvisual || "",
        proteccionrespiratoria: data?.proteccionrespiratoria || "",
        proteccionauditiva: data?.proteccionauditiva || "",
        proteccionmanual: data?.proteccionmanual || "",
        proteccionpies: data?.proteccionpies || "",
        ropaproteccion: data?.ropaproteccion || "",
        sistemabloeti: data?.sistemabloeti || "",
        accesorioadicional: data?.accesorioadicional || "",
        otraproteccion: data?.otraproteccion || "",
        //EPP contra caidas
        arnescuerpo: data?.arnescuerpo || "",
        esligaimpacto: data?.esligaimpacto || "",
        eslingaposicionamiento: data?.eslingaposicionamiento || "",
        eslingay: data?.eslingay || "",
        esliga60: data?.esliga60 || "",
        tieoff: data?.tieoff || "",
        lvvertical: data?.lvvertical || "",
        lvhorizontal: data?.lvhorizontal || "",
        puntoanclaje: data?.puntoanclaje || "",
        //Otros controles
        proctrabajo: data?.proctrabajo || "",
        duchas: data?.duchas || "",
        trabajoalturas: data?.trabajoalturas || "",
        bloqueoetiquetado: data?.bloqueoetiquetado || "",
        trabajofrio: data?.trabajofrio || "",
        controlincedios: data?.controlincedios || "",
        senalizacion: data?.senalizacion || "",
        medevac: data?.medevac || "",
        proteccioncaidas: data?.proteccioncaidas || "",
        explosividad: data?.explosividad || "",
        trabajocaliente: data?.trabajocaliente || "",
        controlderrames: data?.controlderrames || "",
        hojaseguridad: data?.hojaseguridad || "",
        trabajoaltura: data?.trabajoaltura || "",
        primerosauxilios: data?.primerosauxilios || "",
        barrerafisica: data?.barrerafisica || "",
        espacioconfinado: data?.espacioconfinado || "",
        vigiahse: data?.vigiahse || "",
        bypass: data?.bypass || "",
        trabajoelectrico: data?.trabajoelectrico || "",
        otroscontroles: data?.otroscontroles || "",
        //Equipo de seguridad
        casconodielectrico: data?.casconodielectrico || "",
        cascodielectrico: data?.cascodielectrico || "",
        botasnodielectricas: data?.botasnodielectricas || "",
        botasdielectricas: data?.botasdielectricas || "",
        gafaseguridad: data?.gafaseguridad || "",
        caretasoldador: data?.caretasoldador || "",
        trajeimpermeable: data?.trajeimpermeable || "",
        chalecoreflectivo: data?.chalecoreflectivo || "",
        petomangas: data?.petomangas || "",
        protauditivainsercion: data?.protauditivainsercion || "",
        protauditivacopa: data?.protauditivacopa || "",
        overol: data?.overol || "",
        guantescuero: data?.guantescuero || "",
        guanteshilaza: data?.guanteshilaza || "",
        guantesnitrilo: data?.guantesnitrilo || "",
        guanteslatex: data?.guanteslatex || "",
        guantespoliuretano: data?.guantespoliuretano || "",
        tapabocas: data?.tapabocas || "",
        detectorlel: data?.detectorlel || "",
        lineavida: data?.lineavida || "",
        eslingas: data?.eslingas || "",
        respiradorgases: data?.respiradorgases || "",
        respiradorhumos: data?.respiradorhumos || "",
        respiradorpolvos: data?.respiradorpolvos || "",
        respiradorlibre: data?.respiradorlibre || "",
        otrosseguridad: data?.otrosseguridad || "",
    }
}

export function initialValuesAnalisisTrabajo(id, data, form) {
    if (form) {
        return {
            /*Analisis de trabajo */
            Id_formulario: id || "",
            peligros_schema: data.peligros_schema || [],
            evidencia: null
        }
    } else {
        return {
            /*Analisis de trabajo */
            Id_formulario: id || "",
            peligros_schema: data.peligros_schema || [],
            evidencia: data.evidencia || null
        }
    }
}

export function initialValuesCondicionSalud(id, data) {
    return {
        /* Condiciones de salud */
        Id_formulario: id || "",
        //Condiciones salud
        condicionesoptimas: data?.condicionesoptimas || null,
        medicamentossueno: data?.medicamentossueno || null,
        drogasalucinogenas: data?.drogasalucinogenas || null,
        epilepsia: data?.epilepsia || null,
        fobiaalturas: data?.fobiaalturas || null,
        capacitacion: data?.capacitacion || null,
        certificadoaltura: data?.certificadoaltura || null,
        ssvigente: data?.ssvigente || null,
        //Peligros
        deficienciaoxigeno: data?.deficienciaoxigeno || null,
        gasesinflamables: data?.gasesinflamables || null,
        excesooxigeno: data?.excesooxigeno || null,
        particulapolvo: data?.particulapolvo || null,
        vaporestoxicos: data?.vaporestoxicos || null,
        peligrosmecanicos: data?.peligrosmecanicos || null,
        choqueelectrico: data?.choqueelectrico || null,
        peligropiel: data?.peligropiel || null,
        atrapamiento: data?.atrapamiento || null,
        otrospeligros: data?.otrospeligros || "",
    }
}

export function initialValuesAccesoPrevencion(id, data) {
    return {
        /* Sistema de acceso y prevención */
        Id_formulario: id || "",
        escaleratijera: data?.escaleratijera || "",
        escaleraextension: data?.escaleraextension || "",
        escaleraplataforma: data?.escaleraplataforma || "",
        andamiocarga: data?.andamiocarga || "",
        andamiopozo: data?.andamiopozo || "",
        elevadores: data?.elevadores || "",
        barandas: data?.barandas || "",
        conos: data?.conos || "",
        cadenas: data?.cadenas || "",
        cerramientos: data?.cerramientos || "",
        cinta: data?.cinta || "",
        avisos: data?.avisos || "",
        //sistemas de prevencion
        rotacionpersonal: data?.rotacionpersonal || null,
        senalizacion: data?.senalizacion || null,
        delimitacion: data?.delimitacion || null,
        procedimientoespacios: data?.procedimientoespacios || null,
        controlacceso: data?.controlacceso || null,
        bloqueo: data?.bloqueo || null,
        analisispeligros: data?.analisispeligros || null,
        //Proteccion afmosfera
        deficienciaoxigeno: data?.deficienciaoxigeno || null,
        enriquesidaoxigeno: data?.enriquesidaoxigeno || null,
        gasescombustibles: data?.gasescombustibles || null,
        gasestoxicos: data?.gasestoxicos || null,
        inflamabilidad: data?.inflamabilidad || "",
        h2s: data?.h2s || "",
        calor: data?.calor || "",
        mediocomuinicacion: data?.mediocomuinicacion || "",
        procedimientoevacua: data?.procedimientoevacua || null,
    }
}

export function initialValuesInspeccionAltura(id, data) {
    return {
        /*Inspección equipos de altura */
        Id_formulario: id || "",
        //Tejidos y correas
        fibrasexternas: data?.fibrasexternas || "",
        cortes: data?.cortes || "",
        grietas: data?.grietas || "",
        estiramiento: data?.estiramiento || "",
        deteriorotejidoco: data?.deteriorotejidoco || "",
        corrosion: data?.corrosion || "",
        quemaduras: data?.quemaduras || "",
        areasduras: data?.areasduras || "",
        decoloracion: data?.decoloracion || "",
        consultaproveedor: data?.consultaproveedor || "",
        moho: data?.moho || "",
        otrostejidos: data?.otrostejidos || "",
        //Costuras
        cortaduras: data?.cortaduras || "",
        deshilachado: data?.deshilachado || "",
        hilos: data?.hilos || "",
        quemadurascosturas: data?.quemadurascosturas || "",
        exposicionquimicos: data?.exposicionquimicos || "",
        otroscosturas: data?.otroscosturas || "",
        //Argollas
        deformaciones: data?.deformaciones || "",
        picaduras: data?.picaduras || "",
        deterioroargollas: data?.deterioroargollas || "",
        defecto: data?.defecto || "",
        corrosionargollas: data?.corrosionargollas || "",
    }
}

export function initialValuesVerificacionDoc(id, data) {
    return {
        /* Verificación de documentos */
        Id_formulario: id || "",
        equipotienecompetencias: data?.equipotienecompetencias || null,
        tieneclaroriesgos: data?.tieneclaroriesgos || null,
        medidasdecontrol: data?.medidasdecontrol || null,
        funcionesyresponsabilidades: data?.funcionesyresponsabilidades || null,
        eppadecuados: data?.eppadecuados || null,
        equpoyherramienta: data?.equpoyherramienta || null,
        todosequiposseguridad: data?.todosequiposseguridad || null,
    };
}

export function initialValuesQuienElabora(id, data) {
    return {
        /*Quien elabora el documento */
        Id_formulario: id || "",
        nombreelabora: data?.nombreelabora || "",
        cedulaelabora: data?.cedulaelabora || "",
        cargoelabora: data?.cargoelabora || "",
        emailelabora: data?.emailelabora || "",
        fecha_firmaelabora: data?.fecha_firmaelabora || null,
    }
}

export function initialValuesQuienDifunde(id, data) {
    return {
        Id_formulario: id || "",
        difundidos: data.difundidos || [],
    };
}

export function initialValuesSupervisorCoordinador(id, data) {
    return {
        /*Supervisor o coordinador de formulario */
        Id_formulario: id || "",
        nombresupervisor: data?.nombresupervisor || "",
        cedulasupervisor: data?.cedulasupervisor || "",
        cargosupervisor: data?.cargosupervisor || "",
        emailsupervisor: data?.emailsupervisor || "",
        fecha_supervisor: data?.fecha_supervisor || null,
    }
}