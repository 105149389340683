import React, { useEffect } from "react";
import { Button, Col, Form, Row, Input, DatePicker, message, Table, Spin } from "antd";
import { useFormik } from "formik";
import { FormStyle } from "../../../comons/styles";
import { initialValuesSupervisorCoordinador } from "../components/initialValues";
import { useSuperCoord } from "../../../../hooks/administrativa/formularios-sig/useSuperCoor";
import { CircularProgress } from "@mui/material";
import moment from 'moment';
import dayjs from "dayjs";

export function SupervisorForm(props) {

  const { updateSuperCoord, cancelLoader, loading } = useSuperCoord();
  const { dataTipoFormulario, onTableRefresh, data, onClose, handlePrev, id, setnewdataSupervisor } = props;

  useEffect(() => {
    cancelLoader()
  }, [data])

  const formik = useFormik({
    initialValues: initialValuesSupervisorCoordinador(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,

    onSubmit: async (formValues) => {
      try {
        await updateSuperCoord(id.Id_supervisor, formValues);
        message.success("Actualización exitosa (Supervisor ó coordinados)");
        setnewdataSupervisor(formValues)
        onClose();
        onTableRefresh();
      } catch (error) {
        message.error("Error en la operación (Supervisor ó coordinador)");
      }
    },
  });

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Nombres y apellidos"
                validateStatus={formik.errors.nombresupervisor && formik.touched.nombresupervisor ? "error" : ""}
                help={formik.touched.nombresupervisor && formik.errors.nombresupervisor ? formik.errors.nombresupervisor : ""}
              >
                <Input
                  name="nombresupervisor"
                  value={formik.values.nombresupervisor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Cédula"
                validateStatus={formik.errors.cedulasupervisor && formik.touched.cedulasupervisor ? "error" : ""}
                help={formik.touched.cedulasupervisor && formik.errors.cedulasupervisor ? formik.errors.cedulasupervisor : ""}
              >
                <Input
                  name="cedulasupervisor"
                  value={formik.values.cedulasupervisor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Cargo"
                validateStatus={formik.errors.cargosupervisor && formik.touched.cargosupervisor ? "error" : ""}
                help={formik.touched.cargosupervisor && formik.errors.cargosupervisor ? formik.errors.cargosupervisor : ""}
              >
                <Input
                  name="cargosupervisor"
                  value={formik.values.cargosupervisor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Email"
                validateStatus={formik.errors.emailsupervisor && formik.touched.emailsupervisor ? "error" : ""}
                help={formik.touched.emailsupervisor && formik.errors.emailsupervisor ? formik.errors.emailsupervisor : ""}
              >
                <Input
                  name="emailsupervisor"
                  value={formik.values.emailsupervisor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Fecha Aprobador"
                validateStatus={formik.errors.fecha_supervisor && formik.touched.fecha_supervisor
                  ? "error"
                  : ""}
                help={formik.touched.fecha_supervisor && formik.errors.fecha_supervisor
                  ? formik.errors.fecha_supervisor
                  : ""}
              >
                <DatePicker
                  name="fecha_supervisor"
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  value={formik.values.fecha_supervisor ? dayjs(formik.values.fecha_supervisor) : null}
                  onChange={(value) => {
                    const newDate = value ? value.toISOString() : null;
                    formik.setValues({ ...formik.values, fecha_supervisor: newDate });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button type="primary" htmlType="button" onClick={handlePrev}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Actualizar
            </Button>
          </div>
        </Form>
      )}
    </>
  );
}

export function SupervisorFullView(props) {
  const { data } = props;
  const { cancelLoader, loading } = useSuperCoord();

  useEffect(() => {
    cancelLoader();
  }, [data]);

  const formik = useFormik({
    initialValues: initialValuesSupervisorCoordinador(data.Id_formulario, data),
  });

  const columns = [
    {
      title: 'Campo',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const dataSource = [
    { field: 'Nombres y apellidos', value: formik.values.nombresupervisor },
    { field: 'Cédula', value: formik.values.cedulasupervisor },
    { field: 'Cargo', value: formik.values.cargosupervisor },
    { field: 'Email', value: formik.values.emailsupervisor },
    { field: 'Fecha Aprobador', value: formik.values.fecha_supervisor ? moment(formik.values.fecha_supervisor).format('DD/MM/YYYY HH:mm:ss') : null },
  ];

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row style={{ fontSize: '25px' }}>Aprobador</Row>
      <hr />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </>
  );
}

export default SupervisorFullView;

// export function SupervisorFullView(props) {

//   const { cancelLoader, loading } = useSuperCoord();
//   const { data } = props;

//   useEffect(() => {
//     cancelLoader()
//   }, [data])

//   const formik = useFormik({
//     initialValues: initialValuesSupervisorCoordinador(data.Id_formulario, data),
//   });

//   return (
//     <>
//     {loading ? (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     ) : (
//     <Form layout="vertical" style={formStyle}>
//     <Row style={{ fontSize: '25px' }} >Aprobador</Row>
//     <hr/>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Nombres y apellidos" >
//             <Input
//                 name="nombresupervisor"
//                 value={formik.values.nombresupervisor}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Cédula" >
//             <Input
//                 name="cedulasupervisor"
//                 value={formik.values.cedulasupervisor}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Cargo" >
//             <Input
//                 name="cargosupervisor"
//                 value={formik.values.cargosupervisor}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Email" >
//             <Input
//                 name="emailsupervisor"
//                 value={formik.values.emailsupervisor}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//             <Form.Item label="Fecha Aprobador" >
//                 <Input
//                     name="fecha_supervisor"
//                     value={formik.values.fecha_supervisor ? moment(formik.values.fecha_supervisor).format('DD/MM/YYYY HH:mm:ss') : null}
//                     readOnly
//                 />
//             </Form.Item>
//         </Col>
//       </Row>
//     </Form>
//     )}
//     </>
//   );
// }