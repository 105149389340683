import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { CommercialForm } from "../../components/Commercial/CommercialForm";
import { TablaGeneral } from "../../components/comons/TablaGeneral";
import { useComercial } from "../../hooks/useComercial";
import { Modal } from 'antd';
import { useAuth } from "../../hooks/useAuth";

const headers = [
  { label: "Código", field: "cod_id" },
  { label: "Grupo", field: "grupo" },
  { label: "Línea", field: "linea" },
  { label: "Sublínea", field: "sublinea" },
  { label: "Tipo/Tecnología", field: "ti" },
  { label: "Marca", field: "marca" },
  { label: "Página Web", field: "web" },
  { label: "Soporte de Línea", field: "soportelinea" },
  { label: "Gerente de Línea", field: "gerentelinea" },
  { label: "Servicio Técnico SYZ", field: "servicio" },
  { label: "SYZ Colombia", field: "colombia" },
  { label: "SYZ Control de Fluidos", field: "peru" },
  { label: "% DESCUENTO MINIMO COMERCIAL SUGERIDO A OFRECER", field: "desccomercial" },
  { label: "% DESCUENTO MAXIMO COMERCIAL SUGERIDO A OFRECER", field: "maxdesccomercial" },
  { label: "Representante ó Distribuidor", field: "representante" },
  { label: "Categoría de producto ", field: "categoria" },
  { label: "Categoría de Marca", field: "categoriamarca" },
  { label: "Meta Anual", field: "metaanual" },
  { label: "Meta Mes", field: "metames" },
  { label: "Meta Anual SYZ", field: "metaanualsyz" },
  { label: "Lista de Precios", field: "listaprecios" },
  { label: "Vigencia Hasta", field: "vigenciahasta" },
  { label: "Descuento", field: "descuento" },
  { label: "Pedido Mínimo", field: "pedidominimo" },
  { label: "Plazo de Pago", field: "plazopago" },
  { label: "Lugar de Entrega", field: "lugarentrega" },
  { label: "Contacto Comercial", field: "contactocomercial" },
  { label: "Contacto Técnico", field: "contactotecnico" },
  { label: "Contacto Compras", field: "contactocompras" },
  { label: "Link Portal", field: "linkportal" },
  { label: "Usuario Portal", field: "usuarioportal" },
  { label: "Clave Portal", field: "claveportal" },
  { label: "Software de Dimensionamiento", field: "swdimensionamiento" },
  { label: "URL Selector", field: "urlselector" },
  { label: "Usuario Selector", field: "usuarioselector" },
  { label: "Clave Selector", field: "claveselector" },
  { label: "Recomendaciones", field: "recomendaciones" },
];

const headersGeneral = [
  { label: "Código", field: "cod_id" },
  { label: "Grupo", field: "grupo" },
  { label: "Línea", field: "linea" },
  { label: "Sublínea", field: "sublinea" },
  { label: "Tipo/Tecnología", field: "ti" },
  { label: "Marca", field: "marca" },
  { label: "Página Web", field: "web" },
  { label: "Soporte de Línea", field: "soportelinea" },
  { label: "Gerente de Línea", field: "gerentelinea" },
  { label: "Servicio Técnico SYZ", field: "servicio" },
  { label: "SYZ Colombia", field: "colombia" },
  { label: "SYZ Control de Fluidos", field: "peru" },
  { label: "% DESCUENTO MINIMO COMERCIAL SUGERIDO A OFRECER", field: "desccomercial" },
  { label: "% DESCUENTO MAXIMO COMERCIAL SUGERIDO A OFRECER", field: "maxdesccomercial" },
  { label: "Representante ó Distribuidor", field: "representante" },
  { label: "Categoría de producto ", field: "categoria" },
  { label: "Categoría de Marca", field: "categoriamarca" },
];

const popoverColumns = [
  { label: "Recomendaciones", field: "recomendaciones" },
];

export default function ComercialPage() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [contentModal, setContentModal] = useState(null);
  const { auth } = useAuth();

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const { loading, Comercial, getComercial, deleteComercial } = useComercial();

  useEffect(() => {
    getComercial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  
  const handleAdd = useCallback(() => {
    setContentModal(
      <CommercialForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setContentModal(
        <CommercialForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Comercial={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    (data) => {
      deleteComercial(data.cod_id);
      onRefetch();
    },
    [deleteComercial, onRefetch]
  );

  
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (

        <>
        {(auth.me?.comercial_full_modify) && (
          <TablaGeneral
            Data={Comercial}
            columns={headers}
            popoverColumns={popoverColumns}
            handleAgregarData={handleAdd}
            handleEditarData={handleUpdate}
            handleEliminarData={handleDelete}
          />
        )}

        {(auth.me?.comercial_only_view && !auth.me?.comercial_full_modify) && (
          <TablaGeneral
            Data={Comercial}
            columns={headers}
            popoverColumns={popoverColumns}
          />
        )}

        {(auth.me?.comercial_only_general) && (
          <TablaGeneral
            Data={Comercial}
            columns={headersGeneral}
          />
        )}
      </>

      )}

       <Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%" // Ajusta el ancho según tus preferencias
        title="Cargue de archivos"
        destroyOnClose={true}
      >
        {contentModal}
      </Modal>
      
    </>
    
  );
}
